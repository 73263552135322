import { CommonModule, Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  IDropdownSettings,
  NgMultiSelectDropDownModule,
} from 'ng-multiselect-dropdown';
import { InvoiceService } from 'src/app/core/services/invoice.service';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { ChangeDetectorRef } from '@angular/core';
import { CurrencyDirectiveDirective } from 'src/app/shared/directives/currency-directive.directive';
import { OnlyNumberAndCaracterDirective } from 'src/app/shared/directives/onlyNumber.directive';
import { AppToggleButtonComponent } from '../../shared/components/app-button-group/app-toggle-button.component';
import {
  AppInputCustomComponent,
  InputMaskTypes,
} from '../../shared/components/app-input-custom/app-input-custom.component';
import {
  AppInputComponent,
  InputType,
} from '../../shared/components/app-input/app-input.component';
import { AppSelectInputComponent } from '../../shared/components/app-select-input/app-select-input.component';
import { DateMode } from 'src/app/core/services/common.service';
import { PaymentModalComponent } from '../../shared/components/payment-modal/payment-modal.component';
import { PaymentType } from 'src/app/shared/models/paymentData';
import { ModalService } from 'src/app/core/services/modal.service';
import { Router } from '@angular/router';
import { AlertService, AlertType } from 'src/app/core/services/alert.service';

@Component({
  selector: 'app-antecipation-invoices',
  standalone: true,
  imports: [
    CommonModule,
    AppButtonComponent,
    NgMultiSelectDropDownModule,
    ReactiveFormsModule,
    OnlyNumberAndCaracterDirective,
    AppToggleButtonComponent,
    AppInputCustomComponent,
    AppInputComponent,
    AppSelectInputComponent,
    PaymentModalComponent,
  ],
  templateUrl: './antecipation-invoices.component.html',
  styleUrl: './antecipation-invoices.component.scss',
})
export class AntecipationInvoicesComponent implements OnInit {
  pageTitle = 'Antecipar Faturas';

  memberPlanId: any;
  nextInvoices: any[] = [];
  nextInvoicesFormated: { id: string; name: string }[] = [];

  isCreditCard: boolean = false;
  InputMaskTypesEnum = InputMaskTypes;
  InputTypeEnum = InputType;
  DateMode = DateMode;

  modalId = 'payment-modal';

  PaymentType = PaymentType;
  paymentType = PaymentType.PIX;

  paymentModalData: any;
  onModalConfirm(): void {}

  logoUrl: string = localStorage.getItem('imageLogo') || '';

  buttonOptions = {
    buttonText: 'Voltar',
    buttonBorderWidth: '1px',
    buttonSize: 'btn btn-md px-3 btn-secondary',
    borderRadius: '25px',
  };

  buttonPixOptions = {
    buttonText: 'PIX',
    buttonBorderWidth: '1px',
    buttonSize: `btn btn-md px-5 btn-primary`,
    borderRadius: '25px',
  };

  buttonCreateAntecipationOptions = {
    buttonText: 'Ir para o pagamento',
    buttonBorderWidth: '1px',
    buttonSize: `btn btn-md px-5 btn-primary`,
    borderRadius: '25px',
  };

  buttonCreditCardOptions = {
    buttonText: 'Cartão de Crédito',
    buttonBorderWidth: '1px',
    buttonSize: 'btn btn-md px-5 btn-primary',
    borderRadius: '25px',
  };

  public nextInvoicesSettings: IDropdownSettings = {
    selectAllText: 'Antecipar Todas',
    unSelectAllText: 'Desmarcar Todos',
    singleSelection: false,
    enableCheckAll: true,
    itemsShowLimit: 3,
    textField: 'name',
    idField: 'id',
  };

  antecipationForm = new FormGroup({
    nextInvoices: new FormControl([], Validators.required),
    amount: new FormControl(0, Validators.required),
    discount: new FormControl(0),
    paymentMethod: new FormControl('', Validators.required),
    paymentDate: new FormControl<Date>(new Date()),
  });

  creditCardformGroup = new FormGroup({
    cardNumberControl: new FormControl<string>(''),
    expirationDateControl: new FormControl<string>(''),
    cvvControl: new FormControl<string>(''),
    cardNameControl: new FormControl<string>(''),
  });

  constructor(
    private location: Location,
    private invoiceService: InvoiceService,
    private modalService: ModalService,
    private router: Router,
    private alertService: AlertService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.memberPlanId = history.state.memberPlanId;

    this.invoiceService.getNextInvoices(this.memberPlanId).subscribe((res) => {
      this.nextInvoices = res;
      const nextList: any[] = [];
      res.forEach((nextInvoice: any) => {
        let next: any = {
          id: `${nextInvoice.refMonth}/${nextInvoice.refYear}`,
          name: `Fatura ${nextInvoice.refMonth}/${nextInvoice.refYear} - Valor: ${nextInvoice.amount} `,
          amount: nextInvoice.amount,
        };

        nextList.push(next);
      }, (this.nextInvoicesFormated = nextList));
    });

    this.antecipationForm.get('amount')?.disable();
  }

  changeInvoices(invoices: any) {
    let valorAmount = 0;
    invoices.forEach((invoice: any) => {
      const [refMonth, refYear] = invoice.id.split('/');
      const amountInvoice = this.nextInvoices.find(
        (i) =>
          i?.refMonth === Number(refMonth) && i?.refYear === Number(refYear)
      )?.amount;
      valorAmount += amountInvoice;
    });
    this.antecipationForm
      .get('amount')
      ?.setValue(Number(valorAmount.toFixed(2)));
    this.antecipationForm.get('amount')?.updateValueAndValidity();
  }

  changeDiscount() {
    const amount = this.antecipationForm.get('amount')?.value;
    const discount = this.antecipationForm.get('discount')?.value;
    if (discount) {
      this.antecipationForm
        .get('amount')
        ?.setValue(amount ? Number((amount - Number(discount)).toFixed(2)) : 0);
    } else {
      this.changeInvoices(this.antecipationForm.get('nextInvoices')?.value);
    }
  }

  setPaymentMethod(value: any) {
    if (value == 1) {
      this.antecipationForm
        .get('paymentMethod')
        ?.setValue('e4359fb3-9fb7-403c-bee6-47a27516085b');
      this.isCreditCard = false;
      this.buttonPixOptions = {
        buttonText: 'PIX Selecionado',
        buttonBorderWidth: '1px',
        buttonSize: `btn btn-md px-5 btn-secondary`,
        borderRadius: '25px',
      };

      this.buttonCreditCardOptions = {
        buttonText: 'Cartão de Crédito',
        buttonBorderWidth: '1px',
        buttonSize: 'btn btn-md px-5 btn-primary',
        borderRadius: '25px',
      };
    } else {
      this.antecipationForm
        .get('paymentMethod')
        ?.setValue('3f316202-7b6b-4890-b5ad-3b1a613bfd6a');
      this.isCreditCard = true;
      this.buttonCreditCardOptions = {
        buttonText: 'Cartão de Crédito Selecionado',
        buttonBorderWidth: '1px',
        buttonSize: 'btn btn-md px-5 btn-secondary',
        borderRadius: '25px',
      };

      this.buttonPixOptions = {
        buttonText: 'PIX',
        buttonBorderWidth: '1px',
        buttonSize: `btn btn-md px-5 btn-primary`,
        borderRadius: '25px',
      };
    }
  }

  createAntecipation(): void {
    const references: any[] = [];
    const nextsInvoices = this.antecipationForm.get('nextInvoices')?.value
      ? this.antecipationForm.get('nextInvoices')?.value
      : null;

    if (nextsInvoices) {
      console.log(nextsInvoices);
      nextsInvoices.forEach((next: any) => {
        const [refMonth, refYear] = next.id.split('/');
        let ref: any = {
          refMonth: refMonth,
          refYear: refYear,
        };
        references.push(ref);
      });
    }

    let antecipationPayload: any = {
      memberPlanId: this.memberPlanId,
      paymentMethod: this.antecipationForm.get('paymentMethod')?.value || '',
      amount: this.antecipationForm.get('amount')?.value || '',
      discount: this.antecipationForm.get('discount')?.value || '',
      paymentDate: this.antecipationForm.get('paymentDate')
        ? this.formatDateToBackend(
            this.antecipationForm.get('paymentDate')?.value
          )
        : '',
      referenceYearMonth: references || '',
    };

    this.invoiceService
      .createAntecipationInvoice(antecipationPayload)
      .subscribe({
        next: (res: any) => {
          let paymentPayload: any = {
            cardCvv:
              Number(this.creditCardformGroup.get('cvvControl')?.value) || null,
            cardExpirationDate:
              this.creditCardformGroup
                .get('expirationDateControl')
                ?.value?.split('-')
                .reverse()
                .join('/') || '',
            cardHolderName:
              this.creditCardformGroup.get('cardNameControl')?.value || '',
            cardNumber:
              Number(
                this.creditCardformGroup.get('cardNumberControl')?.value
              ) || null,
            paymentMethodId: antecipationPayload.paymentMethod,
          };
          if (
            antecipationPayload.paymentMethod ===
            'e4359fb3-9fb7-403c-bee6-47a27516085b'
          ) {
            this.paymentType == PaymentType.PIX;
            this.invoiceService
              .paymentInvoice(res.id, paymentPayload)
              .subscribe({
                next: (data: any) => {
                  this.paymentModalData = {
                    invoiceNumer: data.id,
                    invoiceCode: data.qrCodeData,
                    invoiceCodeImg: data.qrCodeImageUrl,
                  };
                  this.modalService.open(this.modalId);
                  this.checkPayment(res.id);
                },
                error: (error) => {
                  this.invoiceService
                    .finishAntecipation(res.id, false)
                    .subscribe((res) => {});
                },
              });
          } else {
            this.paymentType == PaymentType.CREDITO;
            if (paymentPayload.cardCvv.length > 3) {
              paymentPayload.cardCvv = paymentPayload.cardCvv.substring(0, 3);
            }
            this.invoiceService
              .paymentInvoice(res.id, paymentPayload)
              .subscribe({
                next: () => {
                  this.checkPayment(res.id);
                },
                error: (erro) => {
                  this.invoiceService
                    .finishAntecipation(res.id, false)
                    .subscribe((res) => {});
                },
              });
          }
        },
      });
  }

  checkPayment(invoiceId: any, elapsedTime: number = 0): void {
    this.invoiceService.checkInvoicePaid(invoiceId).subscribe((data) => {
      if (!data) {
        if (elapsedTime >= 180000) {
          this.invoiceService
            .finishAntecipation(invoiceId, false)
            .subscribe(() => {
              setTimeout(() => {
                this.alertService.showAlert(
                  AlertType.INFO,
                  'Fatura cancelada após 3 minutos sem pagamento.'
                );
              }, 3000);
              this.modalService.close(this.modalId);
              this.router.navigate(['/dashboard']);
            });
        } else {
          setTimeout(() => {
            this.checkPayment(invoiceId, elapsedTime + 30000);
          }, 10000);
        }
      } else {
        // Fecha o modal e redireciona ao dashboard caso o pagamento seja detectado
        this.invoiceService
          .finishAntecipation(invoiceId, true)
          .subscribe(() => {
            setTimeout(() => {
              this.alertService.showAlert(
                AlertType.SUCCESS,
                'Faturas antecipadas pagas com sucesso'
              );
            }, 3000);
            this.modalService.close(this.modalId);
            this.router.navigate(['/dashboard']);
          });
      }
    });
  }

  formatDateToBackend(date: any): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Mês começa do 0
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const milliseconds = String(date.getMilliseconds()).padStart(3, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
  }

  back() {
    this.location.back();
  }
}
